import { useState } from 'react';

import * as S from './styles';

type MenuType = '회원 관리' | '게시글 관리';

function DashBoard() {
  const [menu, setMenu] = useState<MenuType>('회원 관리');

  return (
    <>
      <S.Container>
        <S.Title>{menu}</S.Title>
        <S.CardContainer>
          <S.SideBar>
            <h3>관리 메뉴</h3>
            <S.MenuList>
              <li>회원 관리</li>
              <li>게시글 관리</li>
            </S.MenuList>
          </S.SideBar>
          <S.Contents>
            {menu === '회원 관리' ? (
              <S.MemberList>d</S.MemberList>
            ) : (
              <S.PostList>d</S.PostList>
            )}
          </S.Contents>
        </S.CardContainer>
      </S.Container>
    </>
  );
}

export default DashBoard;
