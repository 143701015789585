import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  max-width: 1400px;
  height: 70vh;

  color: #333333;
  font-size: 1rem;
  font-weight: bold;

  margin: auto;
  padding: 1.7rem 1.2rem;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;

  width: 100%;
  height: 100%;

  margin: auto;
  padding: 1.7rem 1.2rem;
`;

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 12rem;
  height: 100%;

  h3 {
    font-size: 1.2rem;
    font-weight: bold;

    padding-bottom: 1rem;
    border-bottom: 2px solid #000000;
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const MenuList = styled.ul`
  margin-top: 1rem;
  li {
    list-style: none;
    margin: 1rem 0;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 2.5rem;

  color: #333333;
  border: 1px solid #cccccc;
  border-radius: 25px;
  font-size: 1rem;

  margin: 2rem 0 1rem 0;
`;

const Title = styled.h2`
  color: #222222;
  font-size: 1.6rem;

  margin: 2rem 0;
`;

const MemberList = styled.ul`
  li {
    list-style: none;
    margin: 1rem 0;
  }
`;

const PostList = styled.ul`
  li {
    list-style: none;
    margin: 1rem 0;
  }
`;

export {
  Container,
  CardContainer,
  SideBar,
  Contents,
  MenuList,
  Button,
  Title,
  MemberList,
  PostList,
};
