import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import DashBoard from 'pages/Dashboard';
import Entry from 'pages/Entry';
import GlobalStyles from 'styles/GlobalStyles';

function App() {
  return (
    <Router>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Entry />} />
        <Route path="/dashboard" element={<DashBoard />} />
      </Routes>
    </Router>
  );
}

export default App;
