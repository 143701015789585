import { useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import * as S from './styles';

function Entry() {
  const navigate = useNavigate();
  const userIdRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const login = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!userIdRef.current || !passwordRef.current) return;

    const userId = userIdRef.current.value;
    const password = passwordRef.current.value;

    // login logic

    navigate('/dashboard');
  };

  return (
    <>
      <S.Container>
        <S.CardContainer>
          <S.Title>LINKOOK ADMIN</S.Title>
          <S.Form onSubmit={login}>
            <label>
              <span>아이디</span>
              <input
                type="text"
                placeholder="아이디"
                ref={userIdRef}
                required
              />
            </label>
            <label>
              <span>비밀번호</span>
              <input
                type="password"
                placeholder="********"
                ref={passwordRef}
                required
              />
            </label>

            <S.Button type="submit">로그인</S.Button>
          </S.Form>
        </S.CardContainer>
      </S.Container>
    </>
  );
}

export default Entry;
